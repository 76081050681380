<template>
  <SiteNav/>
  <router-view v-if="$beet.isReady"/>

  <transition name="fade">
    <Modal v-if="modal"/>
  </transition>

  <transition name="fade">
    <Tray v-if="tray"/>
  </transition>

  <Gdpr :options="{backgroundColor: '#242424', color: '#fff'}"/>
</template>

<script>
import {useLangRouter} from "@/extensions/langRouter";
import Modal from "@/components/Modal";
import Tray from "@/components/Tray";
import SiteNav from "@/components/SiteNav";
import Gdpr from "@/components/gdpr/Gdpr.vue";

export default {
  name: 'App',
  setup() {
    useLangRouter()
  },
  components: {Gdpr, SiteNav, Tray, Modal},
  beforeMount() {
    this.$beet.fetchCoreData()
  },
  computed: {
    modal() {
      return this.$store.state.modal
    },
    tray() {
      return this.$store.state.tray
    }
  }
}
</script>
