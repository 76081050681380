<template>
  <h2 class="section__title">RÉFÉRER UN CANDIDAT</h2>

  <p class="section__text">Nous sommes régulièrement à la recherche de nouveaux talents. Vous connaissez le candidat parfait pour l’un de nos postes?</p>
  <p class="section__text">Envoyez-nous sa candidature incluant son curriculum vitae et s’il est engagé, vous recevrez une prime de 400$ !</p>
  <p class="section__text">Tous les champs sont obligatoires.</p>
  <br/>
  <transition name="fade" mode="out-in">
    <div v-if="sent">
      <div class="form__confirm-icon">
        <Icon id="checkmark"/>
      </div>

      <p class="form__confirm">
        Nous avons bien reçu la candidature, merci! L’un de nos représentants vous contactera sous peu afin d’établir un suivi avec vous.
      </p>
    </div>

    <form @submit.prevent="post" class="form" v-else>

      <div class="form__section">
        Vos informations
      </div>

      <div class="form__row">
        <label class="input-text">
          <div class="input-text__label">Prénom</div>
          <input required type="text" class="input-text__input" v-model="form.referralName"/>
        </label>

        <label class="input-text">
          <div class="input-text__label">Nom</div>
          <input required type="text" class="input-text__input" v-model="form.referralLastname"/>
        </label>
      </div>

      <div class="form__row">
        <label class="input-text">
          <div class="input-text__label">Adresse courriel</div>
          <input required type="email" class="input-text__input" v-model="form.referralEmail"/>
        </label>

        <label class="input-text">
          <div class="input-text__label">Numéro de téléphone</div>
          <input required type="tel" class="input-text__input" v-model="form.referralPhone"/>
        </label>
      </div>

      <div class="form__section">
        Informations du candidat
      </div>

      <div class="form__row">
        <label class="input-text">
          <div class="input-text__label">Prénom</div>
          <input required type="text" class="input-text__input" v-model="form.name"/>
        </label>

        <label class="input-text">
          <div class="input-text__label">Nom</div>
          <input required type="text" class="input-text__input" v-model="form.lastname"/>
        </label>
      </div>

      <div class="form__row">
        <label class="input-text">
          <div class="input-text__label">Adresse courriel</div>
          <input required type="email" class="input-text__input" v-model="form.email"/>
        </label>

        <label class="input-text">
          <div class="input-text__label">Numéro de téléphone</div>
          <input required type="tel" class="input-text__input" v-model="form.phone"/>
        </label>
      </div>

      <div class="input-text__label -mtop">Domaine d’intérêt</div>
      <select v-model="form.jobType" class="input-select" required>
        <option v-for="(option, key) in options" :key="`option-${key}`" :value="key">{{option}}</option>
      </select>

      <label class="input-text">
        <div class="form__row -spread">
          <label for="message" class="input-text__label">Message</label>
          <button class="form__file input-text__label" @click.prevent="openFile">
            <transition name="fade" mode="out-in">
              <span v-if="form.file">{{form.file.name}}</span>
              <span v-else>Joindre un CV</span>
            </transition>
            <input type="file" required class="input-file" ref="file" @change="handleFile"/>
            <Icon id="file"/>
          </button>
        </div>
        <textarea class="input-text__input" rows="5" id="message" v-model="form.message" required/>
      </label>

      <button class="button form__button">
        Soumettre la candidature
      </button>
    </form>

  </transition>
</template>

<script>
import http from "@/extensions/http";

export default {
  name: "FormReferral",
  data() {
    return {
      form: {
        referralName: '',
        referralLastname: '',
        referralEmail: '',
        referralPhone: '',
        name: '',
        lastname: '',
        email: '',
        phone: '',
        jobType: 0,
        message: '',
        file: null
      },
      options: {
        0: 'Sélectionner un domaine d\'intérêt',
        1: 'Chauffeur/Livraison',
        2: 'Entretien/Mécanique',
        3: 'Finance et comptabilité',
        4: 'Opérations, gestion d’usine et facilités',
        5: 'Qualité',
        6: 'Service à la clientèle'
      },
      sent: false
    }
  },
  methods: {
    openFile() {
      let input = this.$refs['file']
      input.click()
    },
    handleFile() {
      this.form.file = this.$refs['file'].files[0]
    },
    post() {
      const formData = new FormData();
      Object.entries(this.form).forEach((entry) => {formData.append(entry[0], entry[1])})
      http.post('/referral', formData).then(() => {
        this.sent = true
      })
    }
  }
}
</script>
