<template>
  <div class="navbar">
    <div class="navbar__logo">
      <img src="/images/logo.svg"/>
    </div>
    <div class="navbar__menu" @click="isopen = !isopen">
      <transition name="fade" appear mode="out-in">
        <Icon id="menu" v-if="!isopen"/>
        <Icon id="menuclose" v-else/>
      </transition>
    </div>
  </div>
  <transition name="fade" appear >
    <div class="nav-menu" v-if="isopen">
      <div>
        <div class="nav-menu__item" v-for="(item, key) in links" :key="key" @click="scrollToRef(item.ref)">{{item.label}}</div>
      </div>

      <div class="footer-banner">
        <div class="footer-banner__row">
          <div class="footer-banner__row-item" @click="scrollToRef('jobs')">
            <Icon id="cible"/>
            Postes disponibles
          </div>
          <div class="footer-banner__row-separator"/>
          <a href="mailto:emplois@ca.crh.com" class="footer-banner__row-item">
            <Icon id="candidature"/>
            Candidature spontanée
          </a>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
export default {
  name: "SiteNav",
  data() {
    return {
      isopen: false,
      links: [
        {label: 'LA FORCE DU GROUPE', ref: 'force'},
        {label: 'NOS VALEURS', ref: 'valeur'},
        {label: 'LES AVANTAGES DEMIX', ref: 'avantage'},
        {label: 'DES POSSIBILITÉS D’AVANCEMENT', ref: 'avancement'},
        {label: 'DES POSTES-CLÉS', ref: 'jobs'},
        {label: 'MÉTIER SPÉCIALISÉS', ref: 'reference'},
        {label: 'ÉTUDIANTS ET DIPLÔMÉS', ref: 'etudiant'},
        {label: 'FAQ', ref: 'faq'},
      ]
    }
  },
  methods: {
    scrollToRef(key) {
      this.isopen = false
      const el = document.getElementById(key)
      el.scrollIntoView({behavior: 'smooth', block: "start",})
    },
    openForm() {
      this.$store.state.tray = {component: 'FormCandidature'}
    }
  }
}
</script>
