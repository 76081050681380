<template>
  <h2 class="section__title">DÉFINIR UNE ALERTE EMPLOI</h2>

  <p class="section__text">Veuillez sélectionner les critères qui vous intéressent. Un courriel vous sera envoyé dès
    qu’un poste répondant à vos critères sera affiché.</p>

  <p class="section__text"> N’hésitez pas à nous envoyer une candidature spontanée si le poste que vous cherchez ne se
    trouve pas dans la liste.</p>
  <br/>
  <transition name="fade" mode="out-in">
    <div v-if="sent">
      <div class="form__confirm-icon">
        <Icon id="checkmark"/>
      </div>

      <p class="form__confirm">
       La demande d'alerte couriel à été bien reçu, merci!
      </p>
    </div>

    <form class="form" v-else @submit.prevent="post">
      <div class="jobs-filter__section -top -line">
        <div class="jobs-filter__section-row" @click="dropType = !dropType">
          <div>Domaine</div>
          <Icon id="carret" class="jobs-filter__carret" :class="{'-reverse' : dropType}"/>
        </div>

        <DropDown :is-open="dropType">
          <div class="form__row -spread -wrap">
            <InputCheckbox class="checkbox__half" v-for="(item, key) in types" :key="`type-${key}`"
                           v-model="filters.types[key]" :label="item.name"/>
          </div>
        </DropDown>
      </div>

      <div class="jobs-filter__section -line">
        <div class="jobs-filter__section-row" @click="dropLocalisation = !dropLocalisation">
          <div>Localisation</div>
          <Icon id="carret" class="jobs-filter__carret" :class="{'-reverse' : dropLocalisation}"/>
        </div>

        <DropDown :is-open="dropLocalisation">
          <div class="form__row -spread -wrap">
            <InputCheckbox class="checkbox__half" v-for="(item, key) in locations" :key="`type-${key}`"
                           v-model="filters.locations[key]" :label="item"/>
          </div>
        </DropDown>
      </div>

      <br/>

      <label class="input-text">
        <div class="input-text__label">Adresse courriel</div>
        <input type="email" class="input-text__input" required v-model="email"/>
      </label>

      <br/>

      <button class="button form__button">
        S’inscrire à cette alerte
      </button>

      <transition name="fade" mode="out-in">
        <div v-if="error" class="form__error">{{error}}</div>
        <div v-else/>
      </transition>

    </form>

  </transition>
</template>

<script>
import InputCheckbox from "@/components/InputCheckbox";
import http from "@/extensions/http";

export default {
  name: "FormAlert",
  components: {InputCheckbox},
  data() {
    return {
      sent: false,
      dropType: true,
      dropLocalisation: false,
      error: null,
      email: '',
      filters: {
        types: {},
        locations: {}
      },
    }
  },
  computed: {
    locations() {
      return this.$beet.locations
    },
    types() {
      return this.$beet.jobTypes
    }
  },
  methods: {
    post() {
      if (Object.keys(this.filters.types).length && Object.keys(this.filters.locations).length) {
        const form = {}
        form.jobTypeIds = Object.keys(this.filters.types).filter(key => this.filters.types[key])
        form.locationsIds = Object.keys(this.filters.locations).filter(key => this.filters.locations[key])
        form.email = this.email
        http.post('/alert', form).then(() => {
          this.sent = true
        })
      } else {
        this.error = 'Veuillez sélectionner au moins un domaine et une localité'
        setTimeout(() => {
          this.error = null
        }, 3000)
      }

    }
  }
}
</script>
