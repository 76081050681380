<template>
  <div class="checkbox" @click="$emit('update:modelValue', !modelValue)">
    <div class="checkbox__icon">
      <svg v-if="!modelValue" id="uuid-5bdd262f-1783-4da2-8bb8-765f4be9d230" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M16,16H0V0H16V16ZM1,15H15V1H1V15Z" style="fill:#646464;"/></svg>
      <svg v-else id="uuid-01b351b5-18bf-409d-903c-84f06c00a104" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.00006 16"><g><polygon points="13.14996 5.50995 6.60999 11.90997 2.85999 8.14996 4.26996 6.73999 6.63 9.08997 11.75006 4.07996 13.14996 5.50995" style="fill:#ed1c24;"/><path d="M0,0V16H16.00006V0H0ZM2.85999,8.14996l1.40997-1.40997,2.36005,2.34998,5.12006-5.01001,1.3999,1.42999-6.53998,6.40002-3.75-3.76001Z" style="fill:#ed1c24;"/></g><polygon points="13.14996 5.50995 6.60999 11.90997 2.85999 8.14996 4.26996 6.73999 6.63 9.08997 11.75006 4.07996 13.14996 5.50995" style="fill:#fff;"/></svg>
    </div>

    <span class="checkbox__label">{{label}}</span>
  </div>
</template>

<script>
export default {
  name: "InputCheckbox",
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    label: {
      type: String
    }
  }
}
</script>
