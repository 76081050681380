import {createStore} from 'vuex'

export default createStore({
    state: {
        modal: null,
        tray: null
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})
