<template>
  <div class="tray">
    <PerfectScrollbar style="height: 100vh; width: 100%">
      <div class="tray__content">
        <Component :is="tray.component" />
      </div>
    </PerfectScrollbar>
    <button class="button -square tray__close" @click="close">
      <Icon id="close"/>
    </button>
  </div>
</template>

<script>
import FormCandidature from "@/components/FormCandidature";
import FormReferral from "@/components/FormReferral";
import FormAlert from "@/components/FormAlert";
export default {
  name: "Tray",
  components: {FormAlert, FormReferral, FormCandidature},
  computed: {
    tray()
    {
      return this.$store.state.tray
    }
  },
  methods: {
    close() {
      this.$store.state.tray = null
    }
  }
}
</script>
